import { Outlet } from '@remix-run/react';
import { useEffect } from 'react';

import { useUserRedirect } from '../../hooks/useRedirection';
import { GlobalLoading } from '../GlobalLoading';
import { useIsUserLoaded, useUser } from '../UserContext';
import { buildSearchParamsWithRedirectTo } from './hooks';

/**
 * Requires a user to be authenticated, but does not permit access to "guest" users.
 */
export function UserAccess(props: { children?: JSX.Element }): JSX.Element {
  useUser({ init: true });
  const execRules = useUserRedirect();
  const isUserLoaded = useIsUserLoaded();

  const target = `/login?${buildSearchParamsWithRedirectTo(
    window.location.href
  )}`;

  useEffect(() => {
    if (!isUserLoaded) return;
    execRules([
      { kind: 'noUser', target },
      { kind: 'guest', target },
    ]);
  }, [isUserLoaded, execRules, target]);

  if (!isUserLoaded) {
    return <GlobalLoading debug='user-access' />;
  }

  return props.children ? props.children : <Outlet />;
}
