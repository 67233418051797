import React, { useEffect, useMemo, useState } from 'react';

import { LogLevel } from '@lp-lib/logger-base';

import logger from '../logger/logger';
import { rsCounter, rsInfrequent } from '../utils/rstats.client';
import { type RStatsCounterNamesT } from '../utils/rstats-config';

// From: https://gist.github.com/tomhicks/95d455186fadda2f7d99e6163aa9d360

export type EffectTask = () => Promise<void> | void;

export type TaskQueue = {
  tasks: ReadonlyArray<EffectTask>;
  isProcessing: boolean;
  addTask: (task: EffectTask) => void;
};

export function useStatsAwareTaskQueue(params: {
  shouldProcess: boolean;
  stats?: RStatsCounterNamesT;
}) {
  const { tasks, isProcessing, addTask: innerAddTask } = useTaskQueue(params);

  const log = useMemo(
    () => logger.scoped(`task-queue-${params.stats ?? 'unknown'}`),
    [params.stats]
  );

  const addTask = React.useCallback(
    (task: EffectTask) => {
      innerAddTask(async () => {
        const summary = log.isLevelEnabled(LogLevel.Debug)
          ? task.toString().substring(0, 100)
          : '';
        log.debug('start', { summary });
        params.stats && rsCounter(params.stats)?.start();
        await task();
        params.stats && rsCounter(params.stats)?.end();
        params.stats && rsInfrequent(params.stats);
        log.debug('end', { summary });
      });
    },
    [innerAddTask, log, params.stats]
  );

  return {
    tasks,
    isProcessing,
    addTask,
  };
}

// Note(jialin): The queue can not guarantee the remaining tasks get all consumed after umounting.
// Make sure you create the queue at the higher component layer if it's required.
export function useTaskQueue(params: { shouldProcess: boolean }): TaskQueue {
  const [queue, setQueue] = useState<{
    isProcessing: boolean;
    tasks: Array<EffectTask>;
  }>({ isProcessing: false, tasks: [] });

  useEffect(() => {
    if (!params.shouldProcess) return;
    if (queue.tasks.length === 0) return;
    if (queue.isProcessing) return;

    const task = queue.tasks[0];
    setQueue((prev) => ({
      isProcessing: true,
      tasks: prev.tasks.slice(1),
    }));

    Promise.resolve(task()).finally(() => {
      setQueue((prev) => ({
        isProcessing: false,
        tasks: prev.tasks,
      }));
    });
  }, [queue, params.shouldProcess]);

  return {
    tasks: queue.tasks,
    isProcessing: queue.isProcessing,
    addTask: React.useCallback((task) => {
      setQueue((prev) => ({
        isProcessing: prev.isProcessing,
        tasks: [...prev.tasks, task],
      }));
    }, []),
  };
}

/**
 * Be careful with this. It will sequentially process (via `await`) any tasks
 * enqueued, immediately, and until there are no tasks remaining in the queue.
 * It will also attempt to process tasks when this hook is unmounting. The
 * processing is completely outside of react's lifecycles, so avoid using
 * setState within a task.
 *
 * Use this when you absolutely need tasks to execute. The one use case I (drew)
 * can think of is enqueuing a task, via the consuming component/hook's
 * useEffect cleanup function, that interacts with an external-to-React stateful
 * resource while that consumer is unmounting (meaning the children have already
 * unmounted and subsequent child effects would never execute).
 */
export function useTaskQueueRunUntilEmptyUnsafe(): {
  addTask: (task: EffectTask) => void;
  shouldProcess: (enabled: boolean) => void;
  clear: () => void;
} {
  const [q] = useState(() => new RunUntilEmptyTaskQueue());

  useEffect(() => {
    return () => {
      // Best effort to flush any remaining tasks.
      q.process();
    };
  }, [q]);

  return {
    addTask: q.addTask,
    shouldProcess: q.enableProcessing,
    clear: q.clear,
  };
}

/**
 * Notice how this is fully "out" of React's control. This hopefully indicates
 * how unsafe (relative to the guarantees that React gives you) this is,
 * potentially.
 */
class RunUntilEmptyTaskQueue {
  private processing = false;
  private tasks: EffectTask[] = [];
  private shouldProcess = true;

  // These functions are bound to allow for destructured react hook usage.

  addTask = async (task: EffectTask) => {
    this.tasks.push(task);
    await this.process();
  };

  enableProcessing = async (enabled: boolean) => {
    const prev = this.shouldProcess;
    this.shouldProcess = enabled;
    // Only trigger a `process` tick if it went from disabled -> enabled
    if (prev === false) await this.process();
  };

  clear = () => {
    this.tasks.length = 0;
  };

  async process() {
    if (this.processing || !this.shouldProcess) {
      return;
    }
    this.processing = true;
    while (this.tasks.length) {
      const task = this.tasks.shift();
      if (!task) continue;
      await task();
    }
    this.processing = false;
  }
}
